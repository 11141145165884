import { useState } from 'react'
import SellerProvider from 'src/components/contexts/registerSellerContext'

import RegisterSellerStep1 from './RegisterSellerStep1'
import RegisterSellerStep2 from './RegisterSellerStep2'
import RegisterSellerStep3 from './RegisterSellerStep3'
import smileIcon from '../../../images/icons/smile.svg'
import ModelModal from '../ModelModal'

import './style.scss'

export interface ContentModalProps {
  title: string
  description: string
}

export default function FormSeller() {
  const [isError, setIsError] = useState(false)

  const [formStep, setFormStep] = useState<number>(0)
  const [showModal, setShowModal] = useState<boolean>(false)
  const [contentModal, setContentModal] = useState<ContentModalProps>({
    title: '',
    description: '',
  })

  return (
    <main>
      <SellerProvider>
        <section className="bodyRegisterSeller">
          <h2 className="bodyTitleRegisterSeller">
            Responda os campos abaixo e seja um parceiro Decathlon!
          </h2>
          <section className="summaryRegisterSeller">
            <div className="summaryRegisterSeller-container">
              <span
                className={`summaryRegisterSeller-index ${
                  formStep + 1 === 1 ? 'active' : ''
                }`}
              >
                1
              </span>
              <span className="summaryRegisterSeller-span">
                Informações Iniciais
              </span>
            </div>
            <div className="summaryRegisterSeller-container">
              <span
                className={`summaryRegisterSeller-index ${
                  formStep + 1 === 2 ? 'active' : ''
                }`}
              >
                2
              </span>
              <span className="summaryRegisterSeller-span">Dados da Loja</span>
            </div>
            <div className="summaryRegisterSeller-container">
              <span
                className={`summaryRegisterSeller-index ${
                  formStep + 1 === 3 ? 'active' : ''
                }`}
              >
                3
              </span>
              <span className="summaryRegisterSeller-span">
                Dados de Contato
              </span>
            </div>
          </section>
          <section
            className={`containerForms containerFormStep${formStep + 1} ${
              isError ? 'StepError' : ''
            } ${isError}`}
            style={{
              transform: `translateX(-${formStep * 100}vw)`,
            }}
          >
            <RegisterSellerStep1
              formStep={formStep}
              setFormStep={setFormStep}
            />
            <RegisterSellerStep2
              formStep={formStep}
              setFormStep={setFormStep}
              setIsError={setIsError}
            />
            <RegisterSellerStep3
              formStep={formStep}
              setFormStep={setFormStep}
              setShowModal={setShowModal}
              setContentModal={setContentModal}
              setIsError={setIsError}
            />
          </section>
        </section>
        {showModal ? (
          <ModelModal
            title={contentModal?.title}
            description={contentModal?.description}
            setShowModal={setShowModal}
            icon={smileIcon}
            style={{ width: '25rem' }}
          />
        ) : null}
      </SellerProvider>
    </main>
  )
}
