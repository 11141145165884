import { useState } from 'react'
import { setStorage } from 'src/utils/storage'

import check from '../../../images/icons/shape.svg'
import type { RegisterSellerStepsProps } from './typings'
import data, { hover } from './data'

export default function RegisterSellerStep1({
  formStep,
  setFormStep,
}: RegisterSellerStepsProps) {
  const [sellerCategory, setSellerCategory] = useState<string>('')
  const [inputSellerCategory, setInputSellerCategory] = useState<string>('')

  const [erroMessage, setErrorMessage] = useState<string>('')

  function handleClick() {
    if (!sellerCategory && !inputSellerCategory) {
      setErrorMessage(
        'Por favor, selecione uma das opções abaixo para continuar.'
      )

      return
    }

    if (sellerCategory) {
      setStorage('category', sellerCategory)
    } else if (inputSellerCategory) {
      setStorage('category', inputSellerCategory)
    }

    setFormStep(formStep + 1)
  }

  return (
    <section className="containerForm containerFormStep1">
      <div className="containerCategoryTitle">
        <h2 className="categoryTitle">
          Escolha apenas uma categoria que represente sua loja:
        </h2>
        <section className="containerError">
          {erroMessage && <p className="errorReportMessage">{erroMessage}</p>}
        </section>
      </div>
      <section className="containerCategories">
        {data.map(({ categoryImg, categoryTitle }, index) => (
          <div key={index} className="containerCategory">
            <button
              onClick={() => {
                setSellerCategory(categoryTitle)
                setInputSellerCategory('')
              }}
            >
              {sellerCategory === categoryTitle ? (
                <div
                  className="backgroundCategoryActive"
                  style={{ backgroundImage: `url(${categoryImg})` }}
                >
                  <img className="backgroundCheck" src={check} alt={check} />
                  <img
                    src={hover}
                    alt={categoryTitle}
                    className="categoryImage"
                    style={{ opacity: 0.7, backgroundImage: `url(${hover})` }}
                  />
                </div>
              ) : (
                <img
                  src={categoryImg}
                  alt={categoryTitle}
                  className={`categoryImage ${
                    sellerCategory === categoryTitle ? 'active' : null
                  }`}
                />
              )}
            </button>
            <h4 className="categoryTitle">{categoryTitle}</h4>
          </div>
        ))}
      </section>
      <label className="containerAnotherCategory">
        Se você não identificou a categoria que procurava, descreva ela abaixo:
        <input
          type="text"
          maxLength={50}
          onFocus={({ target }) => setInputSellerCategory(target.value)}
          value={inputSellerCategory}
          onChange={({ target }) => {
            if (/[^a-zà-ùâ-ûã-ũá-ú]/gi.test(target.value)) {
              return
            }

            setSellerCategory('')
            setInputSellerCategory(target.value)
          }}
        />
      </label>
      <section className="containerButtons">
        <button
          type="button"
          className="buttonSubmitForm"
          onClick={() => handleClick()}
        >
          CONTINUAR
        </button>
      </section>
    </section>
  )
}
