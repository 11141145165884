import { GatsbySeo } from 'gatsby-plugin-next-seo'
import FormSeller from 'src/components/marketplace/RegisterSeller'

const desc =
  'Quer se tornar um parceiro Decathlon? Cadastre-se e venha vender no marketplace da maior loja de artigos esportivos!'

function Page() {
  return (
    <>
      <GatsbySeo title="Cadastro | Decathlon" description={desc} />
      <FormSeller />
    </>
  )
}

export default Page
