import type { PropsWithChildren } from 'react'
import React, { createContext, useState } from 'react'

const DEFAULT_VALUE = {
  businessInfo: {
    razaoSocial: '',
    cnpj: '',
    faturamento: '',
    productsQuantity: '',
    ticketValuetion: '',
    reclameAqui: '',
    pageWeb: '',
    sellerLinkAnotherMarketplace: '',
    cep: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    fone1: '',
    fone2: '',
    responsible: '',
    mailResponsibleMarketplace: '',
    mailResponsibleSac: '',
  },
  category: '',
  setBusinessInfo: () => {},
  setCategory: () => {},
}

export interface BusinessInfo {
  razaoSocial: string
  cnpj: string
  faturamento: string
  productsQuantity: string
  ticketValuetion: string
  reclameAqui: string
  pageWeb: string
  sellerLinkAnotherMarketplace: string
  cep: string
  street: string
  number: string
  neighborhood: string
  city: string
  state: string
  fone1: string
  fone2: string
  responsible: string
  mailResponsibleMarketplace: string
  mailResponsibleSac: string
}

interface State {
  businessInfo: BusinessInfo
  category: string
}

interface Context extends State {
  setBusinessInfo: React.Dispatch<React.SetStateAction<BusinessInfo>>
  setCategory: React.Dispatch<React.SetStateAction<string>>
}

export const RegisterSellerContext = createContext<Context>(DEFAULT_VALUE)

const SellerProvider: React.FC = ({ children }: PropsWithChildren) => {
  const [businessInfo, setBusinessInfo] = useState(DEFAULT_VALUE.businessInfo)
  const [category, setCategory] = useState(DEFAULT_VALUE.category)

  const value = {
    businessInfo,
    setBusinessInfo,
    category,
    setCategory,
  }

  return (
    <RegisterSellerContext.Provider value={value}>
      {children}
    </RegisterSellerContext.Provider>
  )
}

export default SellerProvider
