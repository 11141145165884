import { images } from 'src/images/registerSeller/importsImage'

export const hover = images.hoverImage

const categories = [
  {
    categoryImg: images.academia,
    categoryTitle: 'Academia e Fitness',
  },
  {
    categoryImg: images.basquete,
    categoryTitle: 'Basquete',
  },
  {
    categoryImg: images.beach,
    categoryTitle: 'Beach Tennis',
  },
  {
    categoryImg: images.boxe,
    categoryTitle: 'Boxe e Artes Marciais',
  },
  {
    categoryImg: images.camping,
    categoryTitle: 'Camping e Trilha',
  },
  {
    categoryImg: images.canoagem,
    categoryTitle: 'Canoagem',
  },
  {
    categoryImg: images.ciclismo,
    categoryTitle: 'Ciclismo',
  },
  {
    categoryImg: images.corridaeCaminhada,
    categoryTitle: 'Corrida e Caminhada',
  },
  {
    categoryImg: images.futebol,
    categoryTitle: 'Futebol',
  },
  {
    categoryImg: images.hipismo,
    categoryTitle: 'Hipismo',
  },
  {
    categoryImg: images.mergulho,
    categoryTitle: 'Mergulho',
  },
  {
    categoryImg: images.natacao,
    categoryTitle: 'Natação',
  },
  {
    categoryImg: images.patinete,
    categoryTitle: 'Patinete',
  },
  {
    categoryImg: images.patins,
    categoryTitle: 'Patins',
  },
  {
    categoryImg: images.pesca,
    categoryTitle: 'Pesca',
  },
  {
    categoryImg: images.skate,
    categoryTitle: 'Skate',
  },
  {
    categoryImg: images.suplementos,
    categoryTitle: 'Suplementos',
  },
  {
    categoryImg: images.surf,
    categoryTitle: 'Surf',
  },
  {
    categoryImg: images.teniseSquase,
    categoryTitle: 'Tennis e Squash',
  },
  {
    categoryImg: images.volei,
    categoryTitle: 'Vôlei',
  },
  {
    categoryImg: images.wakeboi,
    categoryTitle: 'Wakeboard',
  },
  {
    categoryImg: images.yoga,
    categoryTitle: 'Yoga',
  },
]

export default categories
