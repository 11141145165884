import React from 'react'

import './style.scss'

interface Styles {
  width?: string
}

interface ModelModalProps {
  title: string
  description: string
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  icon?: string
  style?: Styles | ''
}

export default function ModelModal({
  title,
  description,
  icon = '',
  style = '',
  setShowModal,
}: ModelModalProps) {
  return (
    <section className="modelModal">
      <section className="containerModal" style={{ ...style }}>
        {icon ? (
          ''
        ) : (
          <button
            type="button"
            className="closeModal"
            onClick={() => {
              setShowModal(false)
            }}
          >
            X
          </button>
        )}
        <h2 className="containerModalTitle">{title}</h2>
        <div className="containerModalDescription">{description}</div>
        {icon ? (
          <>
            <img src={icon} alt="Icone demonstrando felicidade" width={35} />
            <button
              type="button"
              className="buttonRedirectHome"
              onClick={() => (window.location.pathname = '/')}
            >
              Ir para a Decathlon
            </button>
          </>
        ) : null}
      </section>
    </section>
  )
}
