import type { SellerRegisterStep3 } from '../components/marketplace/RegisterSeller/typings'

interface CreatePerson {
  seller: SellerRegisterStep3
  idOrg: string
}

interface AutoFillWithCEPProps {
  seller: SellerRegisterStep3
  setSeller: React.Dispatch<React.SetStateAction<SellerRegisterStep3>>
  setErrorReport: React.Dispatch<React.SetStateAction<SellerRegisterStep3>>
}

export interface NewBusinessInfo {
  address?: string
  razaoSocial: string
  cnpj?: string
  categoria?: string
  integradora?: string
  faturamento?: number
  productsQuantity?: number
  ticketValuetion?: number
  reclameAqui?: string
  pageWeb?: string
  sellerLinkAnotherMarketplace?: string
  cep?: string
  street?: string
  number?: string
  neighborhood?: string
  city?: string
  state?: string
  fone1?: string
  fone2?: string
  responsible?: string
  mailResponsibleMarketplace?: string
  mailResponsibleSac?: string
}

export const autoFillWithCEP = ({
  seller,
  setSeller,
  setErrorReport,
}: AutoFillWithCEPProps) => {
  return seller.cep
    ? fetch(`https://viacep.com.br/ws/${seller.cep}/json/`)
        .then((res) => res.json())
        .then((cepData) => {
          setErrorReport((prevState: any) => ({
            ...prevState,
            cep: '',
            neighborhood: '',
            street: '',
            state: '',
            city: '',
          }))
          setSeller((prevState: any) => ({
            ...prevState,
            cep: cepData.cep,
            neighborhood: cepData.bairro,
            street: cepData.logradouro,
            state: cepData.uf,
            city: cepData.localidade,
          }))
        })
    : null
}

export async function createOrg({
  businessInfo,
  endpoint,
}: {
  businessInfo: NewBusinessInfo
  endpoint: string
}) {
  return fetch(endpoint, {
    method: 'POST',
    body: JSON.stringify({
      name: businessInfo?.razaoSocial,
      address: businessInfo?.address,
      address_postal_code: businessInfo?.cep,
      address_country: 'Brasil',
      address_admin_area_level_1: businessInfo?.state,
      address_locality: businessInfo?.city,
      address_sublocality: businessInfo?.neighborhood,
      address_route: businessInfo?.street,
      address_street_number: businessInfo?.number,
      c9f43ed678b59019736c7b74a488fbf696292bad: businessInfo?.categoria,
      '47bf409580395a0ae5aedc8eec49eccde520a5b0': businessInfo?.integradora,
      '1053508f2eedb00959ff3c6172bf29adb68427db':
        businessInfo?.mailResponsibleSac,
      '3f0c1ad30ac55c8706635b125668333cc5864af8':
        businessInfo?.sellerLinkAnotherMarketplace,
      '3c09dfbf5253c412786c1ef8ab816b12005406a4': businessInfo?.reclameAqui,
      a00ebdfe63f5f69bfcdf20c7a390112a31ba6048: businessInfo?.ticketValuetion,
      eba6b726a655103566919c95b4011aaafb6bd55d: businessInfo?.productsQuantity,
      '7fc946d18973cf89513330844f2105b4d9cdd58a': businessInfo?.faturamento,
      '68f2223723340cb825ecb1038d70ced52c15d92c': businessInfo?.cnpj,
      d08f8bb02a00c9737baca117aadfd69df6fcb04c: businessInfo?.razaoSocial,
      '437867772d579c1c22133015b607d3b46a651ad6': businessInfo?.pageWeb,
    }),
  })
    .then((res) => res.json())
    .catch((err) => console.error(err))
}

export async function createPerson({ seller, idOrg }: CreatePerson) {
  const phonePerson = seller.fone2
    ? [
        { value: seller.fone1.replace(/\W/g, '') },
        { value: seller.fone2.replace(/\W/g, '') },
      ]
    : [{ value: seller.fone1.replace(/\W/g, '') }]

  return fetch('/api/seller/addPerson', {
    method: 'POST',
    body: JSON.stringify({
      name: seller?.responsible,
      org_id: idOrg,
      email: [{ value: seller?.mailResponsibleMarketplace }],
      phone: phonePerson,
    }),
  }).then((res) => res.json())
}

export async function createFallbackInMDv2ToCaptureSeller(
  body: NewBusinessInfo
) {
  const serializerBusinessInfo = {
    organization: {
      nome: body.razaoSocial,
      quantidadeProdutos: body.productsQuantity,
      valorTicket: body.ticketValuetion,
      linkMarketplaces: body.sellerLinkAnotherMarketplace,
      endereco: body.address,
      razaoSocial: body.razaoSocial,
      cnpj: body.cnpj,
      faturamento: body.faturamento,
      reclameAqui: body.reclameAqui,
      site: body.pageWeb,
      categoria: body.categoria,
      integradora: body.integradora,
    },
    person: {
      fone1: body.fone1,
      fone2: body.fone2,
      nome: body.responsible,
      emailMarketplace: body.mailResponsibleMarketplace,
      emailSac: body.mailResponsibleSac,
    },
  }

  return fetch(
    'https://decathlonstore.myvtex.com/api/dataentities/pipedrive/documents?_schema=fallback',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(serializerBusinessInfo),
    }
  )
}
