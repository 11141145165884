import academia from 'src/images/registerSeller/academia.png'
import basquete from 'src/images/registerSeller/basquete.png'
import beach from 'src/images/registerSeller/beach.png'
import boxe from 'src/images/registerSeller/boxe.png'
import camping from 'src/images/registerSeller/camping.png'
import canoagem from 'src/images/registerSeller/canoagem.png'
import ciclismo from 'src/images/registerSeller/ciclismo.png'
import corridaeCaminhada from 'src/images/registerSeller/corrida-e-caminhada.png'
import futebol from 'src/images/registerSeller/futebol.png'
import hipismo from 'src/images/registerSeller/hipismo.png'
import hoverImage from 'src/images/registerSeller/hoverImage.png'
import mergulho from 'src/images/registerSeller/mergulho.png'
import natacao from 'src/images/registerSeller/natacao.png'
import patinete from 'src/images/registerSeller/patinete.png'
import patins from 'src/images/registerSeller/patins.png'
import pesca from 'src/images/registerSeller/pesca.png'
import skate from 'src/images/registerSeller/skate.png'
import suplementos from 'src/images/registerSeller/suplementos.png'
import surf from 'src/images/registerSeller/surf.png'
import teniseSquase from 'src/images/registerSeller/tenis-e-squase.png'
import volei from 'src/images/registerSeller/volei.png'
import wakeboi from 'src/images/registerSeller/wakeboi.png'
import yoga from 'src/images/registerSeller/yoga.png'

export const images = {
  academia,
  basquete,
  beach,
  boxe,
  camping,
  canoagem,
  ciclismo,
  corridaeCaminhada,
  futebol,
  hipismo,
  hoverImage,
  mergulho,
  natacao,
  patinete,
  patins,
  pesca,
  skate,
  suplementos,
  surf,
  teniseSquase,
  volei,
  wakeboi,
  yoga,
}
