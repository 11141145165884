import type { ChangeEvent, FormEvent } from 'react'
import { useContext, useState } from 'react'
import { RegisterSellerContext } from 'src/components/contexts/registerSellerContext'
import {
  convertStringToNumber,
  maskCurrency,
  replaceCurrencyForString,
} from 'src/utils/convertCurrency'
import { maskCNPJ } from 'src/utils/masks'

import InputFormSeller from './InputFormSeller'
import type {
  ErrorReportPropsStep2,
  RegisterSellerStepsProps,
  SellerRegisterStep2,
} from './typings'

interface RegisterSellerStep2Props extends RegisterSellerStepsProps {
  setIsError: React.Dispatch<React.SetStateAction<boolean>>
}

function ValidFormAndNextStep(
  seller: any,
  setErrorReport: React.Dispatch<React.SetStateAction<any>>
) {
  let isValid = true

  if (!seller.razaoSocial) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      razaoSocial: 'Campo obrigatório',
    }))
  }

  if (!seller.cnpj) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      cnpj: 'Campo obrigatório',
    }))
  } else if (seller.cnpj.length !== 18) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      cnpj: 'CNPJ inválido',
    }))
  }

  if (!seller.faturamento) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      faturamento: 'Campo obrigatório',
    }))
  } else if (
    convertStringToNumber(replaceCurrencyForString(seller.faturamento)) < 1
  ) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      faturamento: 'Faturamento inválido',
    }))
  }

  if (!seller.productsQuantity) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      productsQuantity: 'Campo obrigatório',
    }))
  }

  if (parseFloat(seller.productsQuantity) < 1) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      productsQuantity: 'Número inválido',
    }))
  }

  if (!seller.ticketValuetion) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      ticketValuetion: 'Campo obrigatório',
    }))
  } else if (
    convertStringToNumber(replaceCurrencyForString(seller.ticketValuetion)) < 1
  ) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      ticketValuetion: 'Ticket inválido',
    }))
  }

  if (parseFloat(seller.ticketValuetion) < 0) {
    isValid = false
    setErrorReport((prevState: any) => ({
      ...prevState,
      ticketValuetion: 'Número inválido',
    }))
  }

  return isValid
}

export default function RegisterSellerStep2({
  formStep,
  setFormStep,
  setIsError,
}: RegisterSellerStep2Props) {
  const { businessInfo, setBusinessInfo } = useContext(RegisterSellerContext)

  const [errorReport, setErrorReport] = useState<ErrorReportPropsStep2>({
    razaoSocial: '',
    cnpj: '',
    faturamento: '',
    productsQuantity: '',
    ticketValuetion: '',
    reclameAqui: '',
    pageWeb: '',
    sellerLinkAnotherMarketplace: '',
  })

  const [seller, setSeller] = useState<SellerRegisterStep2>({
    razaoSocial: '',
    cnpj: '',
    faturamento: '',
    productsQuantity: '',
    ticketValuetion: '',
    reclameAqui: 'Não Possui',
    pageWeb: '',
    sellerLinkAnotherMarketplace: '',
  })

  function handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault()
    const isValidForm = ValidFormAndNextStep(seller, setErrorReport)

    // eslint-disable-next-line vtex/prefer-early-return
    if (isValidForm) {
      setIsError(false)
      seller.cnpj = seller.cnpj.replace(/[./-]/g, '')

      setBusinessInfo({ ...businessInfo, ...seller })
      setFormStep(formStep + 1)

      return
    }

    setIsError(true)
  }

  return (
    <>
      <section className="containerForm containerFormStep2">
        <form
          onSubmit={async (e) => handleSubmit(e)}
          className="containerForm-form"
        >
          <InputFormSeller
            errorReport={errorReport}
            name="razaoSocial"
            text="Razão social*"
          >
            <input
              id="razaoSocial"
              type="text"
              name="razaoSocial"
              maxLength={80}
              placeholder="Razão social do cartão CNPJ"
              className="sellerRegisterInput"
              value={seller.razaoSocial}
              onChange={({ target }) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    razaoSocial: '',
                  })
                }

                setSeller({ ...seller, razaoSocial: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="cnpj"
            text="Qual o CNPJ da sua loja?*"
          >
            <input
              id="cnpj"
              type="text"
              name="cnpj"
              maxLength={18}
              placeholder="__.___.___/____-__"
              className="sellerRegisterInput"
              value={seller.cnpj}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                target.value = target.value.replace(/\D/g, '')
                if (target.value.length === 14) {
                  setErrorReport({
                    ...errorReport,
                    cnpj: '',
                  })
                }

                target.value = maskCNPJ(target.value)
                setSeller({ ...seller, cnpj: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="faturamento"
            text="Faturamento médio em Marketplace*"
          >
            <input
              id="faturamento"
              type="text"
              min={0.0}
              max={90000000000000000}
              step="0.01"
              name="faturamento"
              placeholder="R$ 0,00"
              className="sellerRegisterInput"
              value={seller.faturamento}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (target.value.length > 20) {
                  return
                }

                if (target.value) {
                  const onlyNumber = replaceCurrencyForString(target.value)

                  target.value = maskCurrency(convertStringToNumber(onlyNumber))
                  setErrorReport({
                    ...errorReport,
                    faturamento: '',
                  })
                }

                setSeller((prevState) => ({
                  ...prevState,
                  faturamento: target.value,
                }))
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="productsQuantity"
            text="Quantidade de Produtos*"
          >
            <input
              id="productsQuantity"
              type="number"
              name="productsQuantity"
              placeholder="0"
              className="sellerRegisterInput"
              value={seller.productsQuantity}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (target.value.includes('e')) {
                  target.value = target.value.split('e').join('')
                } else if (target.value.length > 20) {
                  return
                } else if (target.value) {
                  setErrorReport({
                    ...errorReport,
                    productsQuantity: '',
                  })
                }

                setSeller({ ...seller, productsQuantity: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="ticketValuetion"
            text="Ticket Médio*"
          >
            <input
              id="ticketValuetion"
              type="text"
              name="ticketValuetion"
              placeholder="R$ 0,00"
              className="sellerRegisterInput"
              value={seller.ticketValuetion}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (target.value.length > 16) {
                  return
                }

                if (target.value) {
                  const onlyNumber = replaceCurrencyForString(target.value)
                  const decimalNumber = 0.01 * parseFloat(onlyNumber.slice(-2))

                  target.value = maskCurrency(
                    parseFloat(onlyNumber.slice(0, -2)) + decimalNumber
                  )
                  setErrorReport({
                    ...errorReport,
                    ticketValuetion: '',
                  })
                }

                setSeller({ ...seller, ticketValuetion: target.value })
              }}
            />
          </InputFormSeller>
          <InputFormSeller
            errorReport={errorReport}
            name="reclameAqui"
            text="Nota no Reclame Aqui"
          >
            <select
              id="reclameAqui"
              name="reclameAqui"
              className="sellerRegisterInput"
              value={seller.reclameAqui}
              onChange={({ target }) => {
                setSeller({ ...seller, reclameAqui: target.value })
              }}
            >
              <option>Não Possui</option>
              <option>Sem índice</option>
              <option>Não Recomendada</option>
              <option>Regular</option>
              <option>Bom</option>
              <option>Ruim</option>
              <option>Ótimo</option>
              <option>RA1000</option>
            </select>
          </InputFormSeller>
          <label htmlFor="pageWeb" className="containerForm-labels">
            Possui site próprio? Se sim, inclua o link abaixo:
            <input
              id="pageWeb"
              type="text"
              name="pageWeb"
              maxLength={100}
              className="sellerRegisterInput"
              value={seller.pageWeb}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                setSeller({ ...seller, pageWeb: target.value })
              }}
            />
          </label>
          <label
            htmlFor="sellerLinkAnotherMarketplace"
            className="containerForm-labels"
          >
            Link da loja em outros marketplaces
            <input
              id="sellerLinkAnotherMarketplace"
              type="text"
              name="sellerLinkAnotherMarketplace"
              className="sellerRegisterInput"
              value={seller.sellerLinkAnotherMarketplace}
              onChange={({ target }: ChangeEvent<HTMLInputElement>) => {
                if (/\p{Extended_Pictographic}/u.test(target.value)) {
                  return
                }

                setSeller({
                  ...seller,
                  sellerLinkAnotherMarketplace: target.value,
                })
              }}
            />
          </label>
          <section className="containerButtons">
            <button
              type="button"
              className="backStepButton"
              onClick={() => setFormStep(formStep - 1)}
            >
              Voltar
            </button>
            <button type="submit" className="buttonSubmitForm">
              CONTINUAR
            </button>
          </section>
        </form>
      </section>
    </>
  )
}
