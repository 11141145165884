import type { ErrorReportPropsStep2, ErrorReportPropsStep3 } from './typings'

interface InputFormSellerProps {
  errorReport: ErrorReportPropsStep3 | ErrorReportPropsStep2 | any
  name:
    | 'razaoSocial'
    | 'cnpj'
    | 'faturamento'
    | 'productsQuantity'
    | 'ticketValuetion'
    | 'reclameAqui'
    | 'pageWeb'
    | 'sellerLinkAnotherMarketplace'
    | 'cep'
    | 'street'
    | 'number'
    | 'neighborhood'
    | 'city'
    | 'state'
    | 'fone1'
    | 'fone2'
    | 'responsible'
    | 'mailResponsibleMarketplace'
    | 'mailResponsibleSac'
  children: JSX.Element
  text: string
}

export default function InputFormSeller({
  errorReport,
  name,
  children,
  text,
}: InputFormSellerProps) {
  const chave = errorReport[name]

  return (
    <label
      htmlFor={name}
      className={`${chave ? 'errorReport' : ''} containerForm-labels`}
    >
      {text}
      {children}
      {chave ? <p className="errorReportMessage">{chave}</p> : null}
    </label>
  )
}
