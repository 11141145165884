import axios from 'axios'

export async function validCepFormat(cep: string) {
  if (cep.length <= 0) {
    return false
  }

  const validCepBRA = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)

  return !validCepBRA.data.erro
}
